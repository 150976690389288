.date_wrapper {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.date {
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 18px;
}
.date img {
  margin: 0 10px;
}
.smsswitch {
  display: flex;
  gap: 10px;
}
.details_wrapper {
  height: 66%;
  overflow-y: scroll;
}
.details_wrapper::-webkit-scrollbar {
  width: 0;
}
.entry_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 10px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  padding: 10px 20px;
  border-radius: 15px;
}
.entry_wrapper div {
  width: 20%;
}
.entry_date {
  font-size: 1rem;
  font-weight: 500;
}
.charges {
  font-size: 1.2rem;
  font-weight: 500;
}
@media (max-width: 1400px) {
  .date {
    font-size: 0.9rem;
  }
  .date img {
    width: 20px;
  }
  .smsswitch {
    font-size: 0.9rem;
  }
  .entry_date {
    font-size: 0.9rem;
    font-weight: 500;
  }
  .charges {
    font-size: 1rem;
    font-weight: 500;
  }
  .hours {
    font-size: 0.9rem;
  }
}
@media (max-height: 769px) {
  .details_wrapper {
    height: 63%;
  }
}
@media (max-height: 700px) {
  .details_wrapper {
    height: 60%;
  }
}
@media (max-height: 670px) {
  .details_wrapper {
    height: 58%;
  }
}
@media (max-height: 640px) {
  .details_wrapper {
    height: 55%;
  }
}
