@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/poppins/Poppins-Bold.ttf");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/poppins/Poppins-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/poppins/Poppins-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}
@mixin font($family, $size, $weight) {
  font: {
    family: $family;
    size: $size;
    weight: $weight;
  }
}
@mixin header_xl {
  font: {
    family: "Poppins";
    size: 24px;
    weight: 400;
  }
}
@mixin header_l {
  font: {
    family: "Poppins";
    size: 20px;
    weight: 500;
  }
}
@mixin header_m {
  font: {
    family: "Poppins";
    size: 18px;
    weight: 500;
  }
}
@mixin header_s {
  font: {
    family: "Poppins";
    size: 16px;
    weight: 500;
  }
}
@mixin body_l {
  font: {
    family: "Poppins";
    size: 16px;
    weight: 400;
  }
}
@mixin body_m {
  font: {
    family: "Poppins";
    size: 14px;
    weight: 400;
  }
}
@mixin body_s {
  font: {
    family: "Poppins";
    size: 14px;
    weight: 400;
  }
}
@mixin body_pl {
  font: {
    family: "Poppins";
    size: 13px;
    weight: 400;
  }
}
@mixin button_l {
  font: {
    family: "Poppins";
    size: 16px;
    weight: 500;
  }
}
@mixin button_m {
  font: {
    family: "Poppins";
    size: 14px;
    weight: 500;
  }
}
@mixin overline_l {
  font: {
    family: "Poppins";
    size: 14px;
    weight: 400;
  }
}
@mixin caption {
  font: {
    family: "Poppins";
    size: 12px;
    weight: 400;
  }
}
@mixin overline {
  font: {
    family: "Poppins";
    size: 10px;
    weight: 400;
  }
}
@mixin input_l {
  font: {
    family: "Poppins";
    size: 14px;
    weight: 400;
  }
}
@mixin input_m {
  font: {
    family: "Poppins";
    size: 12px;
    weight: 400;
  }
}
