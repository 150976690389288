@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Bold.ttf");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Medium.ttf");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/poppins/Poppins-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}
