.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
  box-sizing: border-box;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ebebeb;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  transition: 0.4s;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translate(17px, -50%);
}
.switch input:checked + .slider {
  background-color: #157ef8;
}
.slider.round {
  border-radius: 10px;
}

.slider.round:before {
  border-radius: 8px;
}
