.Modal {
  position: absolute;
  width: 25%;
  height: 70%;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 32px;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.postmodal {
  position: absolute;
  width: 28%;
  height: 80%;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 32px;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
@media (max-width: 1400px) {
  .Modal {
    width: 40%;
    height: 80%;
  }
  .postmodal {
    width: 40%;
    height: 85%;
  }
}
