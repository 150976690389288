.dropdown_wrapper {
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
}
.dropdown_head {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #157ef8;
  color: #fff;
  box-sizing: border-box;
  font-size: 1rem;
  letter-spacing: 0.25px;
  padding: 20px 32px;
  border-radius: 24px;
  width: 120px;
}
.dropdown_content {
  margin-top: 7px;
  display: block;
  visibility: hidden;
  opacity: 0;
  /* position: absolute; */
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.06);
  min-width: 150px;
  box-sizing: border-box;
  border: 5px solid #fff;
  border-radius: 20px;
  background-color: #fff;
  padding: 18px 8px;
}
.dropdown_content_wrapper {
  min-width: 240px;
  display: block;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  box-sizing: border-box;
  z-index: 1;
}
.dropdown_wrapper:hover .dropdown_content_wrapper,
.dropdown_wrapper:hover .dropdown_content {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.profileImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
}
.profileImageWrapper:hover {
  border: 2px solid #303030;
  border-radius: 50%;
  transition: border 0.3s ease;
}
.profileImageWrapper img:hover {
  transform: scale(0.8);
  transition: transform 0.3s ease;
}

.dropdown_arrow {
  content: url(../../assets/icons/Down.svg);
}
.dropdown_head:hover .dropdown_arrow {
  transform: rotate(-180deg);
  transition: transform 0.2s ease;
}
.dropdown_link {
  text-decoration: none;
  color: #2e2e2e;
  background-color: #fff;
  border: 1px solid #fff;
  font-size: 16px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.dropdown_link:hover {
  border: 1px solid #f0f7fe;
  background-color: #f0f7fe;
  transition: background-color 0.5s ease, border 0.5s ease;
  color: #157ef8;
}
@media (max-width: 1369px) {
  .dropdown_head {
    /* width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; */
    width: 100px;
    font-size: 0.9rem;
    padding: 15px 20px;
    border-radius: 18px;
  }
}
@media (max-width: 1025px) {
  .dropdown_head {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1vmin 1.5vmin !important;
    font-size: 1.5vmin;
  }
  .dropdown_link {
    font-size: 1.5vmin !important;
  }
}
