.heading_wrapper {
  display: flex;
  justify-content: space-between;
}
.modal_heading {
  font-size: 1.2rem;
  font-weight: 500;
}
.backButton {
  cursor: pointer;
}
.description {
  margin: 50px 0 20px 0;
}
.calendar {
  position: absolute;
  top: 0;
  right: 50px;
  z-index: 1;
}
.dateInput {
  border: 2px solid #bcbcbc;
  border-radius: 12px;
  height: 56px;
}
