.date_wrapper {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.date {
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 18px;
}
.details_wrapper {
  height: 66%;
  overflow-y: scroll;
}
.details_wrapper::-webkit-scrollbar {
  width: 0;
}
.date img {
  margin: 0 10px;
}
.entry_wrapper {
  margin: 20px 10px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  padding: 20px;
  border-radius: 15px;
}

.heading_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.column1 {
  width: 5%;
  display: flex;
  align-items: center;
}
.column2 {
  width: 30%;
  font-weight: 500;
  display: flex;
  gap: 10px;
}
.column3 {
  width: 40%;
  color: #707070;
  font-weight: 500;
}
.column4 {
  width: 25%;
  text-align: right;
  color: #ff605c;
  font-weight: 500;
}

.left_head {
  font-size: 1.2rem;
  font-weight: 500;
}
.right_head {
  font-size: 1.2rem;
  color: #707070;
  font-weight: 500;
}
@media (max-width: 1400px) {
  .date {
    font-size: 0.9rem;
  }
  .date img {
    width: 20px;
  }
}
@media (max-height: 769px) {
  .details_wrapper {
    height: 63%;
  }
}
@media (max-height: 700px) {
  .details_wrapper {
    height: 60%;
  }
}
@media (max-height: 670px) {
  .details_wrapper {
    height: 58%;
  }
}
@media (max-height: 640px) {
  .details_wrapper {
    height: 55%;
  }
}
