.heading_wrapper {
  display: flex;
  justify-content: space-between;
}
.modal_heading {
  font-size: 1.2rem;
  font-weight: 500;
}
.backButton {
  cursor: pointer;
}
.description {
  margin: 50px 0 20px 0;
}
.textarea {
  width: 95%;
  margin: 20px 0;
  height: 100px;
  border-radius: 18px;
  border: "2px solid #bcbcbc";
  padding: 20px;
  box-sizing: border-box;
  outline: none;
  resize: none;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.textarea::-webkit-input-placeholder {
  columns: #707070;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.textarea::-moz-placeholder {
  -moz-columns: #707070;
  columns: #707070;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.textarea:-ms-input-placeholder {
  columns: #707070;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.textarea::-ms-input-placeholder {
  columns: #707070;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.textarea::placeholder {
  -moz-columns: #707070;
  columns: #707070;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
