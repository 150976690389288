.stats_container {
  /* height: 20%; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 32px;
  padding: 30px;
}
.stats_firstrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
}
.download_button {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.download_button:hover {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.06);
}
.download_button img {
  width: 20px;
}
.dates_settings {
  display: flex;
}
.date {
  font-size: 1.2rem;
  font-size: 500;
  letter-spacing: 1px;
}
.stats_secondrow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.tabs {
  width: 16.66%;
  border-right: 2px solid #ebebeb;
}
.tabs_numbers {
  font-size: 1rem;
  font-weight: 500;
}
.tabs:last-child {
  border: 0;
}
.searchbar_container {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  height: 48px;
}
.searchbar_left {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 70%;
  height: 48px;
}
.searchbar_wrapper {
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  width: 70%;
  padding: 0 20px;
  height: 48px;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #202020;
}
.searchbar {
  border: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  width: 100%;
  outline: none;
}
.searchbar::-webkit-input-placeholder {
  color: #bcbcbc;
}
.searchbar::-moz-placeholder {
  color: #bcbcbc;
}
.searchbar:-ms-input-placeholder {
  color: #bcbcbc;
}
.searchbar::-ms-input-placeholder {
  color: #bcbcbc;
}
.searchbar::placeholder {
  color: #bcbcbc;
}
.data_container {
  height: 70%;
  overflow-y: scroll;
  overflow-x: unset;
  box-sizing: border-box;
  width: 100%;
}
.data_container::-webkit-scrollbar {
  margin: 0;
  width: 0;
  border-radius: 10px;
  padding: 0;
}
.calendar {
  position: absolute;
  top: 0;
  right: 50px;
  z-index: 1;
}
.internal_settings {
  position: absolute;
  right: 0;
  top: 50px;
  background-color: #fff;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.06);
  padding: 20px;
  border-radius: 16px;
  z-index: 1;
}
.settings_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.settings_row:last-child {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: #202020;
  cursor: pointer;
  box-sizing: border-box;
}
.text {
  font-size: 0.9rem;
  font-weight: 500;
  color: #202020;
}
@media (max-width: 1500px) {
  .stats_wrapper {
    padding: 20px;
  }
}
@media (max-width: 1369px) {
  .searchbar_wrapper {
    padding: 10px;
    border-radius: 15px;
    width: 40%;
  }
}
@media (max-height: 850px) {
  .data_container {
    height: 68%;
  }
}
@media (max-height: 815px) {
  .data_container {
    height: 65%;
  }
}
@media (max-height: 750px) {
  .data_container {
    height: 63%;
  }
}
@media (max-height: 700px) {
  .data_container {
    height: 60%;
  }
}
@media (max-height: 660px) {
  .data_container {
    height: 57%;
  }
}
@media (max-height: 600px) {
  .data_container {
    height: 54%;
  }
}
@media (max-height: 580px) {
  .data_container {
    height: 50%;
  }
}
@media (max-height: 540px) {
  .data_container {
    height: 47%;
  }
}
