.scroll_wrapper {
  height: 100%;
  overflow-y: scroll;
  padding: 10px;
}
.scroll_wrapper::-webkit-scrollbar {
  width: 0;
}
.wrapper,
.wrapper2 {
  padding: 32px;
  box-sizing: border-box;
  border-radius: 32px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  gap: 30px;
  align-items: center;
  margin: 2vh 0;
}
.wrapper2 {
  cursor: pointer;
  text-decoration: none;
  color: #303030;
  font-size: 1rem;
}
.wrapper:first-child {
  margin: 0;
}
.wrapper img,
.wrapper2 img {
  width: 50px;
}
.heading {
  font-size: 1.2rem;
  font-weight: 500;
  color: #303030;
}
.content {
  font-size: 1rem;
}
@media (max-width: 1369px) {
  .heading {
    font-size: 1rem;
  }
  .content {
    font-size: 0.8rem;
  }
  .wrapper img,
  .wrapper2 img {
    width: 30px;
  }
}
