.grid_container {
  display: grid;
  grid-template-columns: 17% 66% 17%;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}
.left_nav {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.grid_item {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  padding: 10px;
}
.grid_item:nth-child(2) {
  margin: 0 20px;
  background-color: #fff;
  padding: 10px 10px;
  z-index: 0;
}
.grid_item:last-child {
  overflow: visible;
}
.logo_wrapper {
  width: 64px;
  height: 64px;
  border-radius: 24px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 32px 0;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 24px;
  width: 64px;
  overflow: hidden;
  transition: width 0.3s;
}
.sidebar:hover {
  width: 180px;
}
.sidebar_content {
  margin-right: 20px;
  color: #202020;
  font-weight: 500;
}
.link {
  position: relative;
  text-decoration: none;
  display: flex;
  margin-bottom: 32px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.link:last-child {
  margin-bottom: 0;
}
.link img {
  padding: 0 20px;
}
.link:hover .sidebar_content {
  color: #157ef8;
}
.border {
  border: 2px solid #fff;
  position: absolute;
  top: 50%;
  height: 6px;
  transform: translateY(-70%);
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Poppins";
}
.link:hover .border {
  border: 2px solid #157ef8;
}
.right_nav {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  flex-wrap: wrap-reverse;
}
.update_wrapper {
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  height: 75vh;
  position: absolute;
  width: 95%;
  bottom: 10px;
}
.grid_item:last-child {
  position: relative;
}
.heading {
  font-size: 1.2rem;
  font-weight: 500;
}
.update_content {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 14px;
  gap: 10;
}
.update_message {
  width: 80%;
}
.time {
  width: 20%;
  text-align: end;
  color: #157ef8;
  font-weight: 500;
}
.update_scroll_wrapper::-webkit-scrollbar {
  margin: 10px 0;
  width: 5px;
  border-radius: 10px;
  padding: 10px 0;
}

/* Track */
.update_scroll_wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.update_scroll_wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.update_scroll_wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}
@media (max-width: 1369px) {
  .logo_wrapper {
    width: 50px !important;
    height: 53px !important;
    border-radius: 15px;
  }
  .left_nav,
  .right_nav {
    gap: 10px;
  }
  .sidebar {
    width: 50px;
    border-radius: 15px;
    font-size: 0.9rem;
  }
  .border {
    height: 3px;
  }
  .sidebar .link img {
    padding: 0 15px;
    height: 20px;
  }
  .update_wrapper .heading {
    font-size: 1rem;
  }
  .update_message {
    font-size: 0.7rem;
  }
  .time {
    font-size: 0.7rem;
  }
}
@media (max-width: 1025px) {
  .logo_wrapper {
    width: 45px !important;
    height: 45px !important;
    border-radius: 12px;
  }
}
