.stats_container {
  height: 20%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  box-sizing: border-box;
  text-align: center;
}
.stats_wrapper {
  width: 32%;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 32px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.stats_numbers {
  font-size: 3rem;
  color: #157ef8;
  font-weight: 500;
}

.stats_description {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
}
.searchbar_container {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  height: 48px;
}
.searchbar_left {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 70%;
  height: 48px;
}
.searchbar_wrapper {
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  width: 70%;
  padding: 0 20px;
  height: 48px;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #202020;
}
.searchbar {
  border: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  width: 100%;
  outline: none;
}
.searchbar::-webkit-input-placeholder {
  color: #bcbcbc;
}
.searchbar::-moz-placeholder {
  color: #bcbcbc;
}
.searchbar:-ms-input-placeholder {
  color: #bcbcbc;
}
.searchbar::-ms-input-placeholder {
  color: #bcbcbc;
}
.searchbar::placeholder {
  color: #bcbcbc;
}
.data_container {
  height: 70%;
  overflow-y: scroll;
  overflow-x: unset;
  box-sizing: border-box;
  width: 100%;
}
.data_container::-webkit-scrollbar {
  margin: 0;
  width: 0;
  border-radius: 10px;
  padding: 0;
}

/* Track */
.data_container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.data_container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.data_container::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}
.data_wrapper {
  width: 98%;
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  padding: 20px 24px;
  margin: 10px 0;
  margin-left: 2px;
  border-radius: 24px;
  /* height: 100px; */
  box-sizing: border-box;
  overflow: hidden;
  transition: height 0.3s ease-in;
}
.info {
  display: grid;
  grid-template-columns: 25% 15% 15% 45%;
}

.box {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.06);
  text-align: center;
  padding: 15px;
  font-size: 0.9rem;
  font-weight: 500;
  color: #202020;
}
.link {
  text-decoration: none;
}
.actions {
  display: grid;
  overflow: hidden;
  grid-template-columns: 9% 9% 9% 9% 9% 9% 9% 9% 9%;
  -moz-column-gap: 2%;
  column-gap: 2%;
  margin: 20px auto 0 auto;
  padding: 5px;
  transition: visibility 0.3s ease;
}
.column1,
.column2,
.column3 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.column2,
.column3 {
  justify-content: space-evenly;
}
.column4 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.name {
  font-size: 1.2rem;
  font-weight: 500;
  color: #202020;
}
.mobile_number {
  font-size: 0.9rem;
  font-weight: 400;
  color: #707070;
  letter-spacing: 1px;
}
.duration,
.amount_type {
  letter-spacing: 0.5px;
  font-size: 0.9rem;
  color: #707070;
}
.amount {
  color: #27ae60;
  font-size: 1.2rem;
  font-weight: 500;
}

@media (max-width: 1500px) {
  .stats_wrapper {
    padding: 20px;
  }
  .stats_numbers {
    font-size: 2.5rem;
  }
  .name {
    font-size: 0.9rem;
  }
  .mobile_number {
    font-size: 0.7rem;
  }
  .duration,
  .amount_type {
    font-size: 0.7rem;
  }
  .column2 img {
    width: 7px;
  }
  .column4 img {
    width: 15px;
  }
  .amount {
    font-size: 0.9rem;
  }
  .box {
    font-size: 0.5rem;
    padding: 10px;
    overflow: hidden;
  }
  .box img {
    width: 15px;
  }
  .data_container {
    height: 65%;
  }
}
@media (max-width: 1369px) {
  .searchbar_wrapper {
    padding: 10px;
    border-radius: 15px;
    width: 70%;
  }
  .searchbar_wrapper img {
    width: 20px;
  }
  .searchbar {
    font-size: 0.8rem;
  }
  .stats_container {
    height: 15%;
  }
  .stats_numbers {
    font-size: 1.7rem;
  }
  .stats_description {
    font-size: 0.7rem;
  }
  .data_container {
    height: 70%;
  }
  .info {
    grid-template-columns: 20% 15% 15% 50%;
  }
}
@media (max-width: 1220px) {
  .stats_container {
    height: 20%;
  }
}
@media (max-width: 1025px) {
  .stats_numbers {
    font-size: 3vmin;
  }
  .stats_description {
    font-size: 1.5vmin;
  }
}
@media (max-height: 850px) {
  .data_container {
    height: 68%;
  }
}
@media (max-height: 725px) {
  .data_container {
    height: 65%;
  }
}
@media (max-height: 626px) {
  .data_container {
    height: 62%;
  }
}
