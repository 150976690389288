@use "../../pages/colors";
@use "../../pages/fonts" as fonts;
.button {
  position: relative;
  overflow: hidden;
  transition: background 400ms;
  outline: 0;
  cursor: pointer;
  border-radius: 10px;
  @include fonts.button_m;
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  -webkit-animation: ripple 600ms linear;
  animation: ripple 600ms linear;
  background-color: #1371de;
}

@-webkit-keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}
