.edit_wrapper {
  height: 74%;
  margin: 20px 0;
  overflow-y: scroll;
  padding: 10px;
}
.edit_wrapper::-webkit-scrollbar {
  width: 0;
}
.details_wrapper {
  border-radius: 24px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  padding: 24px;
  margin-bottom: 20px;
}
.details_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading {
  font-size: 1.2rem;
  font-weight: 500;
  color: #202020;
}
.row {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.internal_wrapper {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.06);
  padding: 15px;
  width: 45%;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.title {
  font-size: 1rem;
  font-weight: 500;
  color: #202020;
}
.value {
  font-size: 1rem;
  font-weight: 500;
  color: #707070;
}
@media (max-width: 1400px) {
  .title {
    font-size: 0.9rem;
  }
  .value {
    font-size: 0.9rem;
  }
  .heading {
    font-size: 1.1rem;
  }
}
@media (max-height: 750px) {
  .edit_wrapper {
    height: 72%;
  }
}
@media (max-height: 700px) {
  .edit_wrapper {
    height: 70%;
  }
}
