.container {
  // @include fonts.input_l;
  background-image: url(../assets/images/backdrop.svg);
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  &_content {
    height: 70vh;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #fff;
    border-radius: 24px;
    padding: 60px;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
  }
  & .logo {
    width: 100px;
  }
  & .heading {
    font-size: 2rem;
    margin-top: 5vh;
    text-align: center;
  }
  & .instruction {
    width: 70%;
    text-align: center;
    margin: 2.5vh 0;
    font-size: 16px;
  }

  @media (max-width: "1369px") {
    .container_content {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 40px 30px;
      height: 80vh;
    }
    .logo {
      width: 80px;
    }
  }
}
.inputRow {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.backButton {
  border-radius: 10px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  left: 0;
}
.backButton:hover {
  background-color: rgba(21, 126, 248, 0.1);
}
.tag_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.tag_heading {
  font-size: 16px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
}
.tag_content {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}
.slider_container {
  width: 100%;
  margin: 2vh 0;
}
.slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background-color: #f4f4f4;
  outline: none;
  transition: 0.2s;
  position: relative;
}
.sliderlabel {
  position: absolute;
}
.slider_indicator {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 12px;
  background-color: #157ef8;
  cursor: pointer;
}
