.stats_container {
  height: 20%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  box-sizing: border-box;
  text-align: center;
}
.stats_wrapper {
  width: 32%;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 32px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.stats_numbers {
  font-size: 3rem;
  color: #157ef8;
  font-weight: 500;
}

.stats_description {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}
.searchbar_container {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  height: 48px;
}
.searchbar_left {
  display: flex;
  gap: 20px;
  align-items: center;
  width: 70%;
  height: 48px;
}
.searchbar_wrapper {
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  width: 70%;
  padding: 0 20px;
  height: 48px;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #202020;
}
.searchbar {
  border: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  width: 100%;
  outline: none;
}
.searchbar::-webkit-input-placeholder {
  color: #bcbcbc;
}
.searchbar::-moz-placeholder {
  color: #bcbcbc;
}
.searchbar:-ms-input-placeholder {
  color: #bcbcbc;
}
.searchbar::-ms-input-placeholder {
  color: #bcbcbc;
}
.searchbar::placeholder {
  color: #bcbcbc;
}
.data_container {
  height: 70%;
  overflow-y: scroll;
  overflow-x: unset;
  box-sizing: border-box;
  width: 100%;
}
.data_container::-webkit-scrollbar {
  margin: 0;
  width: 0;
  border-radius: 10px;
  padding: 0;
}

/* Track */
.data_container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.data_container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.data_container::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}
.data_wrapper {
  width: 99%;
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  padding: 20px 24px;
  margin: 10px 0;
  margin-left: 2px;
  border-radius: 24px;
  /* height: 100px; */
  box-sizing: border-box;
  overflow: hidden;
  transition: height 0.3s ease-in;
  display: flex;
  cursor: pointer;
}
.job_details {
  width: 20%;
}
.date_details {
  width: 20%;
}
.status_details {
  width: 20%;
}
.job_name,
.date,
.status {
  font-size: 1.3rem;
  font-weight: 500;
  color: #202020;
}
.status {
  color: #27ae60;
}
.details {
  color: #707070;
  font-size: 1rem;
  letter-spacing: 1px;
}
.button_wrapper {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 1500px) {
  .stats_wrapper {
    padding: 20px;
  }
  .stats_numbers {
    font-size: 2.5rem;
  }
}
@media (max-width: 1369px) {
  .stats_numbers {
    font-size: 2.2rem;
  }
  .searchbar_wrapper {
    padding: 10px;
    border-radius: 15px;
    width: 40%;
  }
  .job_details {
    width: 30%;
  }
  .date_details,
  .status_details {
    width: 25%;
  }
  .job_name,
  .date,
  .status {
    font-size: 1rem;
  }
  .button_wrapper {
    width: 20%;
  }
  .details {
    font-size: 0.9rem;
  }
}
@media (max-width: 1170px) {
  .stats_numbers {
    font-size: 2rem;
  }
  .stats_description {
    font-size: 12px;
  }
}
@media (max-width: 1025px) {
  .stats_numbers {
    font-size: 3vmin;
  }
  .stats_description {
    font-size: 1.5vmin;
  }
}
@media (max-height: 850px) {
  .data_container {
    height: 68%;
  }
}
@media (max-height: 725px) {
  .data_container {
    height: 65%;
  }
}
@media (max-height: 626px) {
  .data_container {
    height: 62%;
  }
}
