.heading_wrapper {
  display: flex;
  justify-content: space-between;
}
.modal_heading {
  font-size: 1.2rem;
  font-weight: 500;
}
.backButton {
  cursor: pointer;
}
.radiohead {
  font-weight: 500;
  margin: 2vh 0 1vh 0;
}
.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.user {
  text-align: center;
}
.textarea {
  width: 100%;
  margin: 20px 0 0 0;
  height: 100px;
  border-radius: 18px;
  border: "2px solid #bcbcbc";
  padding: 20px;
  box-sizing: border-box;
  outline: none;
  resize: none;
  font-size: 0.9rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.details_wrapper {
  height: 80%;
  overflow-y: scroll;
  overflow-x: visible;
  box-sizing: border-box;
}
.details_wrapper::-webkit-scrollbar {
  width: 0;
}
.textarea::-webkit-input-placeholder {
  columns: #707070;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.textarea::-moz-placeholder {
  -moz-columns: #707070;
  columns: #707070;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.textarea:-ms-input-placeholder {
  columns: #707070;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.textarea::-ms-input-placeholder {
  columns: #707070;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.textarea::placeholder {
  -moz-columns: #707070;
  columns: #707070;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}
