.heading_wrapper {
  display: flex;
  justify-content: space-between;
}
.modal_heading {
  font-size: 1.2rem;
  font-weight: 500;
}
.backButton {
  cursor: pointer;
}
.row {
  margin: 50px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
