.calendar_container {
  background-color: #fff;
  width: 400px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  padding: 20px;
  border-radius: 15px;
  /* display: flex; */
  gap: 20px;
  flex-wrap: wrap;
  box-sizing: border-box;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
}
.calendar_date {
  display: inline;
  /* padding: 10px 20px; */
  width: 45px;
  height: 45px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  border-radius: 10px;
  cursor: pointer;
  /* width: fit-content; */
}
.week_wrapper {
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  padding: 20px;
  border-radius: 15px;
  gap: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
}
.week_name {
  border: 2px solid rgba(188, 188, 188, 0.06);
  margin: 5px 0;
  border-radius: 10px;
  padding: 0 10px;
  box-sizing: border-box;
  cursor: pointer;
}
