input {
  background: none;
  border-width: 0;
  display: block;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
  width: 95%;
  color: #707070;
  font-weight: 500;
}
input::placeholder {
  color: #94afaa;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #303030;
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;
  transition: background-color 5000s ease-in-out 0s;
}

fieldset {
  padding: 3rem 2rem 1.5rem;
}

legend {
  padding: 0 0.5rem;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.hidden__visually {
  border: 0;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  color: #94afaa;
}
.floating_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 56px;
  border: 2px solid #bcbcbc;
  border-radius: 16px;
  padding-right: 1rem;
  position: relative;
  outline: none;
}
.floating {
  transition: background-color 0.2s ease;
  outline: 0;
  width: 100%;
  box-sizing: border-box;
  height: 56px;
}
.image {
  position: absolute;
  right: 1rem;
  display: none;
  cursor: pointer;
}
.floating_wrapper:active > .image,
.floating_wrapper:focus-within > .image {
  display: block;
}
.floating_wrapper:hover,
.floating_wrapper:focus-within {
  outline: none;
}

.floating__input {
  padding: 1.8rem 0 0.6rem 1.5rem;
  font-size: 1rem;
  transition: border-color 0.2s ease;
  outline: none;
}

.floating__input::placeholder {
  color: rgba(0, 0, 0, 0);
}

.floating__label {
  display: block;
  position: relative;
  max-height: 0;
  font-size: #94afaa;
  pointer-events: none;
}

.floating__label::before {
  content: attr(data-content);
  display: inline-block;
  filter: blur(0);
  backface-visibility: hidden;
  transform-origin: left top;
  transition: transform 0.2s ease;
  left: 1.5rem;
  position: relative;
  color: #bcbcbc;
  font-weight: 500;
}
.floating__input:focus + .floating__label::after {
  transform: scale3d(1, 1, 1);
  opacity: 1;
  outline: none;
}
.floating__input:focus + .image::after {
  display: inherit;
}
.floating__input:placeholder-shown + .floating__label::before {
  transform: translate3d(0, -2.5rem, 0) scale3d(1, 1, 1);
}

.floating__label::before,
.floating__input:focus + .floating__label::before {
  transform: translate3d(0, -3rem, 0) scale3d(0.82, 0.82, 1);
  outline: none;
}
.error {
  color: #ff605c;
  font-weight: 500;
  font-size: 0.9rem;
}
/* Searchbar */
.search_image {
  margin-left: 1rem;
}
