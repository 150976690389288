.toppanel {
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
}
.top_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top {
  font-size: 1.2rem;
  font-weight: 500;
}
.top2 {
  font-size: 1rem;
  font-weight: 500;
}
.bottom {
  font-size: 1rem;
  color: #707070;
}
.column4 {
  display: flex;
  gap: 20px;
}
.firstline {
  border: 0.5px solid rgba(0, 0, 0, 0.06);
  margin-top: 3vh;
}
.middle_row {
  margin: 2vh 0;
  display: flex;
  align-items: center;
  gap: 20px;
}
.secondline {
  border: 0.5px solid rgba(0, 0, 0, 0.06);
  margin: 0;
}
.content {
  font-size: 1rem;
  font-weight: 500;
}
.searchbar_wrapper {
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  width: 100%;
  padding: 0 20px;
  height: 48px;
  border-radius: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  color: #202020;
  margin: 2vh 0;
}
.searchbar {
  border: 0;
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-left: 10px;
  width: 100%;
  outline: none;
}
.searchbar::-webkit-input-placeholder {
  color: #bcbcbc;
}
.searchbar::-moz-placeholder {
  color: #bcbcbc;
}
.searchbar:-ms-input-placeholder {
  color: #bcbcbc;
}
.searchbar::-ms-input-placeholder {
  color: #bcbcbc;
}
.searchbar::placeholder {
  color: #bcbcbc;
}
.joblist_wrapper {
  height: 43%;
  overflow-y: scroll;
  padding: 5px;
  margin: 2% 0;
}
.joblist_wrapper::-webkit-scrollbar {
  margin: 0;
  width: 0;
  border-radius: 10px;
  padding: 0;
}
.joblist_row {
  margin: 20px 0;
}
.joblist_row,
.single_row {
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  padding: 2%;
  border-radius: 16px;
  box-sizing: border-box;
}
.single_row {
  width: 48%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.header_row,
.details_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0 0;
}
.details_row {
  flex-wrap: wrap;
}
.heading {
  font-size: 1.2rem;
  font-weight: 500;
}
.left {
  font-weight: 500;
  width: 40%;
}
.right {
  width: 70%;
  text-align: end;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media (max-width: 1400px) {
  .top {
    font-size: 1rem;
  }
  .bottom,
  .content {
    font-size: 0.9rem;
  }
  .left {
    width: 50%;
    font-size: 0.9rem;
  }
  .right {
    width: 50%;
    font-size: 0.9rem;
  }
}
@media (max-height: 860px) {
  .joblist_wrapper {
    height: 41%;
  }
}

@media (max-height: 820px) {
  .joblist_wrapper {
    height: 40%;
  }
}
@media (max-height: 810px) {
  .joblist_wrapper {
    height: 38%;
  }
}
@media (max-height: 770px) {
  .joblist_wrapper {
    height: 36%;
  }
}
@media (max-height: 740px) {
  .joblist_wrapper {
    height: 34%;
  }
}
