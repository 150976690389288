.top_panel {
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top_content {
  font-size: 1.1rem;
  font-weight: 500;
}
.bottom_content {
  margin-top: 10px;
  color: #707070;
}
.icon_row {
  display: flex;
  gap: 20px;
}
.iconbox {
  width: 45px;
  height: 45px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
}
.iconbox:hover {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.06);
}
.iconbox img {
  width: 25px;
}
.content_scroll_wrapper {
  height: 88%;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 20px 5px;
  scrollbar-color: #fff #fff;
  scrollbar-width: 0 0;
}
.content_scroll_wrapper::-webkit-scrollbar {
  width: 0;
}
.content_wrapper {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.heading_row,
.fields_row,
.field_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fields_row {
  margin: 20px 0;
  gap: 20px;
}
.field_row {
  padding: 20px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.06);
  width: 45%;
  border-radius: 20px;
  font-weight: 500;
}
.heading {
  font-size: 1.3rem;
  font-weight: 500;
  color: #202020;
}
@media (max-width: 1400px) {
  .top_content {
    font-size: 0.9rem;
  }
  .bottom_content {
    font-size: 0.7rem;
  }
  .field_row {
    font-size: 0.9rem;
  }
  .heading {
    font-size: 1.1rem;
  }
}
@media (max-height: 790px) {
  .content_scroll_wrapper {
    height: 87%;
  }
}
@media (max-height: 730px) {
  .content_scroll_wrapper {
    height: 85%;
  }
}
@media (max-height: 640px) {
  .content_scroll_wrapper {
    height: 83%;
  }
}
