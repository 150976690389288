.date_wrapper {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.date {
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 18px;
}
.date img {
  margin: 0 10px;
}
.smsswitch {
  display: flex;
  gap: 10px;
}
.employee_stats {
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 20px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.box {
  width: calc(100% / 6);
  box-sizing: border-box;
  padding: 0 20px;
  border-right: 2px solid #ebebeb;
}
.box:last-child {
  border: 0;
}
.boxtop {
  font-weight: 500;
}
.content_wrapper {
  margin-top: 20px;
  height: 57%;
  overflow-y: scroll;
}
.content_wrapper::-webkit-scrollbar {
  width: 0;
}
.details_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  margin: 20px 10px;
  border-radius: 18px;
}
.left_detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}
.right_detail {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.row {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.icon_wrapper {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.icon_wrapper img {
  width: 20px;
}
.comment {
  display: flex;
  gap: 20px;
}
@media (max-width: 1400px) {
  .date {
    font-size: 0.9rem;
  }
  .date img {
    width: 20px;
  }
  .smsswitch {
    font-size: 0.9rem;
  }
  .box {
    font-size: 0.9rem;
    padding: 0 10px;
  }
  .boxbottom {
    font-size: 0.7rem;
  }
  .employee_stats {
    padding: 15px;
    border-radius: 15px;
  }
  .date_wrapper {
    margin: 15px 0;
  }
  .details_wrapper {
    font-size: 0.9rem;
  }
  .right_detail {
    width: 50%;
  }
}

@media (max-width: 1400px) {
  .right_detail {
    width: 55%;
  }
}
@media (max-width: 1369px) and (max-height: 769px) {
  .content_wrapper {
    height: 50vh;
  }
}
