.topbar {
  padding: 20px;
  border-radius: 24px;
  box-sizing: border-box;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
}
.top_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top {
  font-size: 1rem;
  font-weight: 500;
}
.bottom {
  font-size: 0.9rem;
  color: #707070;
}
.actions {
  display: grid;
  overflow: hidden;
  grid-template-columns: 9% 9% 9% 9% 9% 9% 9% 9% 9%;
  -moz-column-gap: 2%;
  column-gap: 2%;
  /* margin: 20px auto 0 auto; */
  padding: 20px 0 20px 20px;

  transition: visibility 0.3s ease;
}
.column1,
.column2,
.column3 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.column2,
.column3 {
  justify-content: space-evenly;
}
.column4 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.name {
  font-size: 1.1rem;
  font-weight: 500;
  color: #202020;
}
.mobile_number {
  font-size: 0.9rem;
  font-weight: 400;
  color: #707070;
  letter-spacing: 1px;
}
.duration,
.amount_type {
  letter-spacing: 0.5px;
  font-size: 0.9rem;
  color: #707070;
}
.amount {
  color: #27ae60;
  font-size: 1.2rem;
  font-weight: 500;
}
.link {
  text-decoration: none;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.06);
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  color: #202020;
}
.info {
  display: grid;
  grid-template-columns: 30% 15% 15% 40%;
}

.box {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.06);
  text-align: center;
  padding: 15px;
  font-size: 0.9rem;
  font-weight: 500;
  color: #202020;
}
@media (max-width: 1500px) {
  .amount {
    font-size: 0.9rem;
  }
  .box {
    font-size: 0.5rem;
    padding: 10px;
    overflow: hidden;
  }
  .box img {
    width: 15px;
  }
  .top {
    font-size: 0.9rem;
  }
  .bottom {
    font-size: 0.7rem;
  }
}
