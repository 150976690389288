.heading_wrapper {
  display: flex;
  justify-content: space-between;
}
.modal_heading {
  font-size: 1.2rem;
  font-weight: 500;
}
.backButton {
  cursor: pointer;
}
.tag_head {
  font-size: 1rem;
  font-weight: 600;
  /* color: #202020; */
  text-align: left;
  margin-bottom: 10px;
}
.tag_content {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}
.content_wrapper {
  height: 80%;
  overflow-y: scroll;
  margin-top: 20px;
  background: transparent;
  padding: 5px;
}
.content_wrapper::-webkit-scrollbar {
  width: 0;
}
.calendar_input {
  border: 2px solid #bcbcbc;
  height: 56px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  position: relative;
  width: 90%;
  -moz-columns: #707070;
  columns: #707070;
  font-size: 0.9rem;
  cursor: pointer;
  margin: 10px 0;
}
.label {
  font-size: 0.7rem;
  top: 5px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #bcbcbc;
}
.left_calender {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.radio_wrapper {
  display: flex;
  margin: 50px 0;
  gap: 20px;
}
@media (max-width: 1400px) {
}
