.heading_wrapper {
  display: flex;
  justify-content: space-between;
}
.modal_heading {
  font-size: 1.2rem;
  font-weight: 500;
}
.backButton {
  cursor: pointer;
}
.description {
  margin: 50px 0 20px 0;
}
.buttons_row {
  display: flex;
  justify-content: space-between;
}
