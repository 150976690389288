.date_wrapper {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.date {
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 18px;
}
.date img {
  margin: 0 10px;
}
.smsswitch {
  display: flex;
  gap: 10px;
}
.content_wrapper {
  height: 67%;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 10px;
}
.content_wrapper::-webkit-scrollbar {
  width: 0;
}
.row {
  box-sizing: border-box;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 14px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin: 20px 0;
}
.date_content {
  font-size: 1rem;
  font-weight: 500;
  color: #202020;
  width: 30%;
}
.amount {
  font-size: 1.2rem;
  width: 30%;
  font-weight: 500;
  color: #202020;
}
.notes {
  font-size: 1rem;
  color: #707070;
  width: 40%;
}
@media (max-width: 1400px) {
  .date {
    font-size: 0.9rem;
  }
  .date img {
    width: 20px;
  }
  .smsswitch {
    font-size: 0.9rem;
  }
  .date_content {
    font-size: 0.9rem;
  }
  .amount {
    font-size: 1rem;
  }
  .notes {
    font-size: 0.9rem;
  }
}

@media (max-height: 930px) {
  .content_wrapper {
    height: 65%;
  }
}
@media (max-height: 890px) {
  .content_wrapper {
    height: 63%;
  }
}
@media (max-height: 820px) {
  .content_wrapper {
    height: 60%;
  }
}
@media (max-height: 780px) {
  .content_wrapper {
    height: 58%;
  }
}
@media (max-height: 740px) {
  .content_wrapper {
    height: 56%;
  }
}
@media (max-height: 715px) {
  .content_wrapper {
    height: 55%;
  }
}
@media (max-height: 700px) {
  .content_wrapper {
    height: 53%;
  }
}
