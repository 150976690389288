.dropdown_wrapper {
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  box-sizing: border-box;
}
.dropdown_head {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #157ef8; */
  border: 2px solid #bcbcbc;
  color: #202020;
  box-sizing: border-box;
  font-size: 1rem;
  letter-spacing: 0.25px;
}
.dropdown_content {
  margin-top: 7px;
  display: block;
  visibility: hidden;
  opacity: 0;
  /* position: absolute; */
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.06);
  min-width: 150px;
  box-sizing: border-box;
  border: 5px solid #fff;
  border-radius: 20px;
  background-color: #fff;
  padding: 18px 8px;
  max-height: 30vh;
  max-width: 400px;
  overflow-y: scroll;
}
.dropdown_content::-webkit-scrollbar {
  margin: 10px 0;
  width: 5px;
  border-radius: 10px;
  padding: 10px 0;
}

/* Track */
.dropdown_content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.dropdown_content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
.dropdown_content::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 10px;
}
.dropdown_content_wrapper {
  min-width: 240px;
  display: block;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  box-sizing: border-box;
  z-index: 1;
}
.dropdown_wrapper:hover .dropdown_content_wrapper,
.dropdown_wrapper:hover .dropdown_content {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.profileImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid transparent;
}
.profileImageWrapper:hover {
  border: 2px solid #303030;
  border-radius: 50%;
  transition: border 0.3s ease;
}
.profileImageWrapper img:hover {
  transform: scale(0.8);
  transition: transform 0.3s ease;
}

.dropdown_arrow {
  content: url(../../assets/icons/Down.svg);
  width: 20px;
}
.dropdown_head:hover .dropdown_arrow {
  transform: rotate(-180deg);
  transition: transform 0.2s ease;
}
.dropdown_link {
  text-decoration: none;
  color: #2e2e2e;
  background-color: #fff;
  border: 1px solid #fff;
  font-size: 16px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dropdown_link:hover {
  border: 1px solid #f0f7fe;
  background-color: #f0f7fe;
  transition: background-color 0.5s ease, border 0.5s ease;
  color: #157ef8;
}
.search_input {
  box-sizing: border-box;
  width: 300px;
  margin: 10px 20px;
  font-size: 1rem;
  padding: 5px 10px;
  border-radius: 10px;
  outline: none;
  border: 2px solid #bcbcbc;
}
