.heading_wrapper {
  display: flex;
  justify-content: space-between;
}
.modal_heading {
  font-size: 1.2rem;
  font-weight: 500;
}
.backButton {
  cursor: pointer;
}
.list_wrapper {
  margin: 20px 0;
  height: 50vh;
  overflow-y: scroll;
}
.list_wrapper::-webkit-scrollbar {
  width: 0;
}
