.data_wrapper {
  background-color: #fff;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  margin: 10px;
  padding: 20px;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.name {
  font-size: 1.2rem;
  font-weight: 500;
  color: #202020;
}
.time {
  font-size: 1rem;
  font-weight: 500;
  color: #707070;
  margin: 10px 0;
}
.icon_wrapper {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.icon_wrapper img {
  width: 20px;
}
.comment {
  display: flex;
  gap: 20px;
}
.attendance_manager {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.row {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
@media (max-width: 1500px) {
  .attendance_manager {
    width: 50%;
  }
}
@media (max-width: 1300px) {
  .attendance_manager {
    width: 60%;
  }
}
